import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
  writeBatch,
  Unsubscribe,
} from "firebase/firestore";

export const useAdminQuickComments = definePiniaStore(
  "/admin/quick/comments",
  () => {
    const db = useFirestore();

    const quickComments = ref<QuickComments | undefined>(undefined);
    const quickCommentsSubscription = ref<Unsubscribe | undefined>();

    const streamQuickComments = () => {
      const quickCommentsRef = collection(db, `/quickComments`);

      if (quickCommentsSubscription.value) {
        quickCommentsSubscription.value();
      }

      quickCommentsSubscription.value = onSnapshot(
        quickCommentsRef,
        (quickCommentsSnapshots) => {
          quickComments.value = quickCommentsSnapshots.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            } as QuickComment;
          });
        }
      );
    };

    const fetchCategorizedQuickComments = async () => {
      const quickCommentsRef = collection(db, `/quickComments`);
      const quickCommentsSnapshots = await getDocs(quickCommentsRef);

      const quickComments = quickCommentsSnapshots.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        } as QuickComment;
      });

      return useCategorizeQuickComments(quickComments);
    };

    const fetchQuickComment = async (quickCommentId: string) => {
      const quickCommentRef = doc(db, `/quickComments/${quickCommentId}`);
      const quickCommentSnapshot = await getDoc(quickCommentRef);

      return {
        ...quickCommentSnapshot.data(),
        id: quickCommentSnapshot.id,
      } as QuickComment;
    };

    const createDefaultComment = () => {
      return {
        category: undefined,
        comment: "",
        levels: [] as String[],
        lessons: [] as String[],
        priority: 0,
      } as QuickComment;
    };

    const saveQuickComment = async (quickComment: QuickComment) => {
      if (quickComment.id == undefined) {
        const quickCommentsRef = collection(db, `/quickComments`);

        const response = await addDoc(quickCommentsRef, quickComment);
        quickComment.id = response.id;
      } else {
        const quickCommentRef = doc(db, `/quickComments/${quickComment.id}`);

        await updateDoc(quickCommentRef, quickComment);
      }
    };

    const bulkAddQuickComments = async (quickComments: QuickComments) => {
      const db = useFirestore();
      const batch = writeBatch(db);

      for (var quickComment of quickComments) {
        const quickCommentsRef = collection(db, `/quickComments`);
        const quickCommentsDoc = doc(quickCommentsRef);
        batch.set(quickCommentsDoc, quickComment);
      }

      await batch.commit();
    };

    const saveQuickComments = async (quickComments: QuickComments) => {
      const db = useFirestore();
      const batch = writeBatch(db);

      for (var quickComment of quickComments) {
        const quickCommentsRef = collection(db, `/quickComments`);
        const quickCommentsDoc = doc(quickCommentsRef, quickComment.id);
        batch.update(quickCommentsDoc, quickComment);
      }

      await batch.commit();
    };

    const deleteQuickComment = async (quickCommentId: string) => {
      const quickCommentRef = doc(db, `/quickComments/${quickCommentId}`);

      await deleteDoc(quickCommentRef);
    };

    const categories = computed(() => {
      var categorieStrings = [] as string[];

      for (var comment of quickComments.value ?? []) {
        if (comment.category == undefined) continue;

        categorieStrings.push(comment.category);
      }

      return [...new Set(categorieStrings)];
    });

    const tags = computed(() => {
      var tagStrings = [] as string[];

      for (var comment of quickComments.value ?? []) {
        if (comment.tags == undefined) continue;

        tagStrings.push(...comment.tags);
      }

      return [...new Set(tagStrings)].sort((a, b) =>
        a.localeCompare(b, "en", { sensitivity: "base" })
      );
    });

    return {
      quickComments,
      categories,
      tags,
      streamQuickComments,
      fetchCategorizedQuickComments,
      saveQuickComment,
      bulkAddQuickComments,
      saveQuickComments,

      fetchQuickComment,
      createDefaultComment,
      deleteQuickComment,
    };
  }
);
